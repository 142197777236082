import React from 'react';
import { Link } from 'gatsby';
import { TiMail } from 'react-icons/ti';
import styled from 'styled-components';
import { Time } from './styles';
import Image from '../components/image';

const Lead = styled.article`
	margin-bottom: 120px;

	a {
		color: inherit;
		text-decoration: none;

		@media (min-width: 40em) {
			display: grid;
			grid-template-columns: 1fr 48px 24px repeat(3, 1fr) 24px;
			grid-template-rows: repeat(6, 100px);
		}
	}

	h2 {
		margin: 0;
		margin-bottom: 1rem;
		font-family: 'Crimson Pro', serif;
		font-size: 2.4rem;
		line-height: 1.2;
		text-shadow:
			2px 2px 0 rgba(0, 213, 255, 0.5),
			-2px -2px 0 rgba(255, 105, 180, 0.5);
		transition: text-shadow 200ms cubic-bezier(0,0,0.3,1);

		&:hover {
			text-shadow:
				4px 4px 0 rgba(0, 213, 255, 0.5),
				-4px -4px 0 rgba(255, 105, 180, 0.5);
		}

		@media (min-width: 40em) {
			font-size: 5rem;
		}
	}

	header {
		@media (min-width: 40em) {
			grid-column: 1 / -1;
			grid-row: 3;
			z-index: 1;
		}
	}

	p {
		font-size: 0.8rem;
		color: #464646;

		@media (min-width: 40em) {
			grid-column: 1 / span 1;
			grid-row: 5;
		}
	}
`;

export default function Feature({ frontmatter: { title, date, path, ...image }, excerpt }) {
	return (
		<Lead>
			<Link to={path}>
				<header>
					<Time>
						<TiMail className="icon" />
						{date}
					</Time>
					<h2>{title}</h2>
				</header>
				<p>{excerpt}</p>
				{image && (
					<Image
						className="primary-image"
						filename={`${image.image_filename}.${image.image_ext}`}
						alt={image.image_alt}
					/>
				)}
			</Link>
		</Lead>
	)
}
