import React from "react"
import { Link, graphql } from "gatsby"
import Layout from "../layouts/layout"
import SEO from "../components/seo"
import styled from "styled-components"
import { TiMail } from "react-icons/ti"
import Feature from "../components/feature"
import { Time } from "../components/styles"

const Homepage = styled.main`
	margin: 0 auto;
	padding: 64px 20px;
	max-width: 1100px;
`;

const Post = styled.article`
	a {
		text-decoration: none;
		color: inherit;
	}

	h3 {
		margin: 0;
		margin-bottom: 1rem;
		font-family: 'Crimson Pro', serif;
		font-size: 1.6rem;
		line-height: 1.2;

		span {
			color: deeppink;
		}
	}

	p {
		font-size: 0.8rem;
		color: #464646;
	}
`;

const Posts = styled.div`
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
	gap: 48px;
`;

export default function IndexPage({ data }) {
	const [feature, ...posts] = data.allMdx.edges.map(({ node }) => ({ ...node }));

	return (
		<Layout>
			<SEO title="Home" />
			<Homepage>
				<Feature {...feature}/>
				<Posts>
					{posts.map(({ frontmatter: { title, date, path }, excerpt }) => (
						<Post key={date}>
							<Link to={path}>
								<Time>
									<TiMail className="icon" />
									{date}
								</Time>
								<h3>{title} <span aria-hidden="true">&mdash;</span></h3>
								<p>{excerpt}</p>
							</Link>
						</Post>
					))}
				</Posts>
			</Homepage>
		</Layout>
	)
}

export const query = graphql`
	query HomepageQuery {
		allMdx(sort: {fields: frontmatter___date, order: DESC}) {
			edges {
				node {
					frontmatter {
						title
						date(formatString: "MMMM DD, YYYY")
						path
						image_filename
						image_ext
						image_alt
					}
					excerpt
				}
			}
		}
	}
`;
